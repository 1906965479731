<template>
    <b-modal 
        ref="ModalInputEmail" 
        v-model="showModal"
        centered
        title="Add Email"
        hide-footer
    >
        <p class="my-2">Please enter your email , We will send import result to your email</p>
        <InputText
            textFloat="Email"
            placeholder="Email"
            type="text"
            name="email"
            isRequired
            v-model="form.email"
            @onKeyup="handleChangeEmail"
        />
        <div v-if="errorEmail">
          <span class="alert-email">Please enter an e-mail address</span>
        </div>
        <b-button class="mt-3 btn-email" block :disabled="hasEmailorOnSubmit" @click="submitForm()">Submit</b-button>
    </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
    components:{
        InputText
    },
    props:{
        form:{
            required:true,
            type:Object
        }
    },
    data(){
        return{
            showModal : false,
            hasEmailorOnSubmit : false,
            errorEmail : false
        }
    },
    created(){
        if(!this.form.email) this.hasEmailorOnSubmit = true;
    },
    methods:{
        show() {
            this.showModal = true;
        },
        close() {
            this.showModal = false;
        },
        handleChangeEmail(value){
            let mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(this.form.email){
                if(mailformat.test(this.form.email)){
                    this.hasEmailorOnSubmit = false;
                    this.errorEmail = false;
                    this.$emit('changeEmail',this.form.email);
                }else{
                    this.hasEmailorOnSubmit = true;
                    this.errorEmail = true;
                }
            }else{
                this.hasEmailorOnSubmit = true;
            }
        },
        submitForm(){
            this.$emit('submit');
            this.showModal = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-email{
    background-color: var(--primary-color);
    color: #fff;
}
.alert-email{
    color: red;
}
::v-deep .modal-title{
    font-weight: 600;
    font-size: 20px;
}
</style>